<script>
import axios from 'axios'
import { AlertTimeout } from '@/RegistrationStage.ts'
import { endpoints } from '@/environments/Endpoints.ts'
import { environmentVariables } from '@/environments/EnvironmentVariables.ts'
import { EnvX, envx } from '@/environments/EnvX.ts'
import { StatesNamesData } from '@/resources/data/StatesNamesData.ts'

export default {
  props: {
    stateCodeProp: {
      type: String,
      default: null
    },
    countyCodeProp: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      states: StatesNamesData,
      selectedStateId: '',
      selectedCountyId: '',
      disableCountyMenu: true,
      counties: [],
      isCountyLoading: false
    }
  },

  computed: {
    nullTimeoutProp() {
      return AlertTimeout.null
    }
  },

  mounted() {
    setTimeout(this.resetStateAndCounty(this.stateCodeProp, this.countyCodeProp), this.nullTimeoutProp)
  },

  methods: {
    async loadCountiesMenuAsync(state) {
      try {
        this.isCountyLoading = true
        this.disableCountyMenu = true
        this.counties = []

        if (state) {
          const axiosCounties = axios.create({
            baseURL: envx.publicFunctionsUrl,
            proxy: false
          })
          axiosCounties
            .post(
              endpoints.statetocountiesEndpoint,
              {
                state: `${state}`,
                format: 'statecountycode'
              },
              {
                params: {
                  code: environmentVariables.STATE_TO_COUNTIES_KEY
                }
              }
            )
            .then((response) => {
              const data = response.data
              EnvX.log(`✅ got counties response: ${JSON.stringify(data)}`)
              var countiesList = []
              Object.entries(data[state]).forEach(([county, countycode]) => {
                countiesList.push({
                  name: county,
                  id: countycode
                })
              })
              this.counties = countiesList
              this.disableCountyMenu = false
              this.isCountyLoading = false
            })
            .catch((error) => {
              EnvX.warn('🧨', error)
              this.isCountyLoading = false
            })
        }
      } catch (exception) {
        EnvX.error('💥', exception)
        this.isCountyLoading = false
      }
    },

    onStateChanged(statecode) {
      this.loadCountiesMenuAsync(statecode).then((this.selectedCountyId = ''), this.onCountyChanged(''))
    },

    onCountyChanged(countycode) {
      this.$emit('callUpdateStateCountyProps', this.selectedStateId, countycode)
    },

    resetStateAndCounty(statecode, countycode) {
      if (!statecode) {
        statecode = ''
      }
      if (!countycode) {
        countycode = ''
      }
      if (this.selectedStateId !== statecode) {
        this.selectedStateId = statecode
        this.loadCountiesMenuAsync(statecode).then((this.selectedCountyId = countycode))
      } else if (this.selectedCountyId !== countycode) {
        this.selectedCountyId = countycode
      }
    }
  }
}
</script>

<template>
  <v-row>
    <v-col d-flex align-center cols="12" sm="6" class="align-center"> <v-select v-model="selectedStateId" name="state" :items="states" item-text="name" item-value="id" :rules="[(v) => !!v || 'State is required']" label="State" required @change="onStateChanged($event)" /> </v-col>
    <v-col d-flex align-center cols="12" sm="6" class="align-center"> <v-select v-model="selectedCountyId" :loading="isCountyLoading" name="county" :items="counties" item-text="name" item-value="id" :rules="[(v) => !!v || 'County is required']" label="County" required :disabled="disableCountyMenu" @change="onCountyChanged($event)" /> </v-col>
  </v-row>
</template>
